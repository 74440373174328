import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/about/Layout';

const AboutPage: React.FC = () => {
  const {
    sidebar: { html }
  } = useStaticQuery(graphql`
    query {
      sidebar: markdownRemark(
        fileAbsolutePath: { glob: "**/about/about-sel.md" }
      ) {
        html
      }
    }
  `);

  return (
    <Layout>
      <Core.Markdown>{html}</Core.Markdown>

      <h2>How did we build it?</h2>

      <Core.VideoEmbed
        src="https://player.vimeo.com/video/360202852?title=0&byline=0&portrait=0"
        width={640}
        height={360}
      />
    </Layout>
  );
};

export default AboutPage;
